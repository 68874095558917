import React, { Component } from "react";

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
//import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';



//photos
import instructorArt from '../../images/instructors/instructor-art.jpg';
import instructorVik from '../../images/instructors/instructor-vik.jpg';

class Instructors extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        //this.props.history.push("/instructors");
    }

    render() {

        return (
            <div className="h-100">
                <Navbar expand="lg" variant={this.state.variant} bg={this.state.variant}>
                    <Container>
                        <Navbar.Brand style={{ margin: '20px', fontSize: '150%' }}>Instructors</Navbar.Brand>
                    </Container>
                </Navbar>

                <Container>
                    <Row className="py-3">
                        <Col>
                            <Image fluid rounded src={instructorArt} alt="Art Fiedler" className="img-thumbnail"
                                style={{
                                    marginLeft: 'auto', marginRight: 'auto', minHeight: '428.5px', minWidth: '342.5px',
                                    maxHeight: '428.5px', maxWidth: '342.5px', width: '50%', display: 'block', border: '0px'
                                }}>
                            </Image>
                        </Col>
                        <Col style={{ minWidth: '400px' }}>
                            <h3>Art Fiedler</h3>
                            <p>
                                My journey to yoga was quite unexpected. In early 2017, I saw my whole life in front of me: and it was not what I wanted.
                                So I jumped on a last minute flight to Japan with no plan… and ended up backpacking the world, seeking perspective and a calling.
                                I found exactly what I was looking for: yoga.
                            </p>
                            <p>
                                Now, I'm happily back in my hometown of Thunder Bay, Ontario teaching Power, Vinyasa, Hatha & Restorative yoga.
                                I teach full time in the studio and online. Covid has shifted everything, so yoga and mindfulness have never been more important.
                                My classes are open to everyone of all levels - leave your ego at the door.

                            </p>
                            <p>
                                My favourite thing in the world is balance.
                                I take my yoga practice seriously, but not myself too seriously.
                                Yoga has helped me find physical and mental well-being, and I strive in helping others do so as well.
                                I completed my RYT-200 Elements of Power training at the famous Yoga Barn in Ubud, Bali
                            </p>
                        </Col>
                    </Row>
                    <Row className="py-3">
                        <Col>
                            <Image fluid rounded src={instructorVik} alt="Art Fiedler" className="img-thumbnail"
                                style={{
                                    marginLeft: 'auto', marginRight: 'auto', minHeight: '428.5px', minWidth: '342.5px',
                                    maxHeight: '428.5px', maxWidth: '342.5px', display: 'block', border: '0px', objectFit: 'cover'
                                }}>
                            </Image>
                        </Col>
                        <Col style={{ minWidth: '400px' }}>
                            <h3>Vik Wilen</h3>
                            <p>
                                I have been practicing yoga since I was 18, after experiencing my first guided yoga class in a Tree Planting camp in NW Ontario.
                                I am trained in a Canadian based yoga lineage called “Modo yoga”, as well as vinyasa and freestyle flow.
                            </p>
                            <p>
                                Before the pandemic, I taught consistently for 8 years and would describe my classes as fast paced and powerful.
                                As I have grown in my own practice, I now prefer to slow things down, using more intentional and mindful movements.
                            </p>
                            <p>
                                I look forward to bringing you a sense of calm and creativity to the mat!!
                            </p>
                        </Col>
                    </Row>
                    
                 
                </Container>
            </div>
        );
    }
}

export default Instructors;