import React, { Component } from "react";

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
//import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';



//photos
import coldplunge from '../../images/privatewellness/coldplunge.jpg';
import sauna from '../../images/privatewellness/sauna.jpg';
import yogadeck from '../../images/privatewellness/yogadeck.jpg';

class Instructors extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        //this.props.history.push("/instructors");
    }

    render() {

        return (
            <div className="h-100">
                <Navbar expand="lg" variant={this.state.variant} bg={this.state.variant}>
                    <Container>
                        <Navbar.Brand style={{ margin: '20px', fontSize: '150%', textWrap: 'wrap' }}>Mindfulness Retreat on Lake Superior</Navbar.Brand>
                    </Container>
                </Navbar>

                <Container>
                    <p>
                        Looking for a unique and rejuvenating experience with family and friends? Enjoy a private yoga class, sauna sessions, cold plunges—or any combination tailored to your preferences.
                    </p>
                    <div class="contact-info">
                        <p>For questions or to check availability, contact Art:</p>
                        <p>📞 807-707-7374</p>
                        <p>📧 <a href="mailto:artofyogacanada@gmail.com">artofyogacanada@gmail.com</a></p>
                    </div>
                    <p>Discover tranquility by the lake!</p>
                    <div className="d-md-flex justify-content-center align-items-center">
                        <Col>
                            <Image className="p-2 m-1" fluid src={yogadeck} alt="yoga on the deck" style={{ margin: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'cover', border: '10px' }} />
                        </Col>
                        <Col>
                            <Image className="p-2 m-1" fluid src={sauna} alt="sauna" style={{ margin: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'cover', border: '10px' }} />
                        </Col>
                        <Col>
                            <Image className="p-2 m-1" fluid src={coldplunge} alt="cold plunge" style={{ margin: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'cover', border: '10px' }} />
                        </Col>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Instructors;