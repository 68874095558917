import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from "react-redux";
import store from "./store";

import PrivateRoute from "./components/private-route/PrivateRoute";

//layout
import TopNavbar from "./components/layout/TopNavBar";

//import components
import Home from "./components/components/Home";
import Studio from "./components/components/Studio";
import Classes from "./components/components/Classes";
import Instructors from "./components/components/Instructors";
//import Purchase from "./components/components/Purchase";
//import PaymentForm from "./components/components/PaymentForm";
import OnlineYoga from "./components/components/OnlineYoga";
import PrivateWellness from "./components/components/PrivateWellness";

//admin
import Login from "./components/auth/Login";
import AdminNavBar from "./components/layout/AdminNavBar";
import AdminDashboard from "./components/components/Admin/Dashboard";
import AdminClasses from "./components/components/Admin/Classes";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">


            <Switch>
              {/* <div>
                <Route path="/admin">
                  <AdminNavBar />
                  <PrivateRoute exact path="/admin/login" component={Login} />
                  <PrivateRoute path="/admin/dashboard" component={AdminDashboard} />
                  <PrivateRoute path="/admin/classes" component={AdminClasses} />
                </Route>
              </div> */}


              <Route path="/" >
                <TopNavbar />
                <Route exact path="/" component={Home} />
                <Route path="/studio" component={Studio} />
                <Route path="/classes" component={Classes} />
                <Route path="/instructors" component={Instructors} />
                <Route path="/privatewellness" component={PrivateWellness} />
                <Route path="/onlineyoga" component={OnlineYoga} />
                {/* <Route path="*" element={<p>Path not resolved</p>} /> */}
              </Route>
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
