import React, { Component } from "react";

//import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from "react-bootstrap/Image";

import sunrise from '../../images/sunrise.JPG';
import aoyBanner from '../../images/aoybanner.png';
import coldplunge from '../../images/home/coldplunge.jpg'

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            classes: [
                {
                    weekDayCode: 1,
                    dayOfTheWeek: 'Monday',
                    daySchedule: [
                        {
                            time: '10 AM',
                            classType: 'Hatha',
                            instructor: 'Art',
                            location: 'MacGregor Rec Center'
                        }
                    ]

                },
                // {
                //     weekDayCode: 2,
                //     dayOfTheWeek: 'Tuesday',
                //     daySchedule: [
                //         {
                //             time: '5-5:45 PM',
                //             classType: 'Flow',
                //             instructor: 'Art',
                //             location: 'Goods & Co.'
                //         }
                //     ]

                // },

                {
                    weekDayCode: 5,
                    dayOfTheWeek: 'Friday',
                    daySchedule: [
                        {
                            time: '10 AM',
                            classType: 'Hips',
                            instructor: 'Art',
                            location: 'Art of Yoga Studio'
                        }
                    ]

                },
                {
                    weekDayCode: 6,
                    dayOfTheWeek: 'Saturday',
                    daySchedule: [
                        {
                            time: '10 AM',
                            classType: 'Online',
                            instructor: 'Art',
                            location: ''
                        },
                        {
                            time: '11 AM',
                            classType: 'Power',
                            instructor: 'Art',
                            location: 'Art of Yoga Studio'
                        },
                        {
                            time: '12 PM',
                            classType: 'Cold Plunge',
                            instructor: 'Art',
                            location: 'Art of Yoga Studio'
                        }
                    ]

                },
                {
                    weekDayCode: 7,
                    dayOfTheWeek: 'Sunday',
                    daySchedule: [
                        {
                            time: '11 AM',
                            classType: 'Sunday Funday Flow',
                            instructor: 'Art',
                            location: 'Art of Yoga Studio'
                        },
                        {
                            time: '12 PM',
                            classType: 'Cold Plunge',
                            instructor: 'Art',
                            location: 'Art of Yoga Studio'
                        }
                    ]

                }
            ]
        };
    }

    componentDidMount() {
        //this.props.history.push("/");
    }

    render() {
        const { classes } = this.state;

        return (
            <div className="h-100">
                <div style={{ textAlign: 'center', fontSize: '40px', color: '#023180', font: 'Quicksand' }}><strong>YOGA. SAUNA. COLD PLUNGE.</strong></div>
                <Image fluid src={sunrise} alt="sunrise" style={{
                    margin: '20px', marginLeft: 'auto', marginRight: 'auto', width: '69.5%',
                    height: '69%', display: 'block', border: '0px'
                }} />

                <Container>
                    <Button onClick={() => { window.location.href = 'https://bookwhen.com/artofyoga' }} style={{ margin: '20px', background: '#99ddff', border: '0px', color: 'black', borderRadius: '0px' }}>
                        Book Class Here
                    </Button>

                    <Row>
                        <Col>
                            <p>
                                Yoga on the Big Lake. Art of Yoga is just a 15 minute drive from Thunder Bay.
                                Come experience yoga on Lake Superior - featuring both in studio & outdoor classes.
                                Yoga is about unplugging from your life, and surrendering to the present moment.
                                We do enough planning for the future and stressing about the past...
                                let's shift our awareness to the present to find peace.
                            </p>
                            <p>
                                Here at Art of Yoga, we welcome all levels of yoga.
                                If you're "not flexible at all", you actually have the most to gain.
                                Are you flexible but have a busy mind? Yoga is the answer.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p><strong>New!</strong> Click <a href="https://www.vikwilen.com/about-3">here</a> to find out more about the <strong>"Yoga Retreat in Mexico 2025"</strong></p>
                        </Col>

                    </Row>
                    {/* <Button onClick={() => { window.location.href = 'https://bookwhen.com/artofyoga#focus=ev-sz0o-20220420100000' }} style={{ margin: '20px', background: '#99ddff', border: '0px', color: 'black', borderRadius: '0px' }}>
                        Purchase
                    </Button> */}
                </Container>

                {/* <Container style={{ background: '#f8f9fa', paddingTop: '20px' }}>
                    <div>Class Schedule</div>
                    <Row xs={1} md={4} className="g-4">
                        {classes.map((yogaClass, index) => (
                            <Col key={index}>
                                <Card style={{ margin: '10px', border: '0px', minHeight: '250px' }}> {
                                    <Card.Header style={{ background: '#99ddff' }}>{yogaClass.dayOfTheWeek}</Card.Header>
                                    <Card.Body>
                                        {yogaClass.daySchedule.map((sched, index) => (

                                            <Row key={index} style={{ margin: '10px' }}>
                                                <div>{sched.time}</div>
                                                <div>{sched.classType} - {sched.instructor}</div>
                                                <div>{sched.location}</div>
                                            </Row>

                                        ))}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container> */}

                <Container>
                    <Button onClick={() => { window.location.href = 'https://bookwhen.com/artofyoga' }} style={{ margin: '20px', background: '#99ddff', border: '0px', color: 'black', borderRadius: '0px' }}>
                        Class Info
                    </Button>
                   
                        <Image src={coldplunge} alt="coldplunge" style={{ translate: '0 -30%', width: "100%", height: "auto", clipPath: "inset(30% 0 0 0)", objectFit: "cover", margin: '20px', marginLeft: 'auto', marginRight: 'auto' }} />
                 

                    <Image fluid rounded src={aoyBanner} alt="Art Fiedler" className="img-thumbnail"
                        style={{
                            translate: '0 -160%',
                            marginLeft: 'auto', marginRight: 'auto', width: '80%',
                            display: 'block', border: '0px', objectFit: 'scale-down'
                        }}>
                    </Image>
                    {/* maxHeight: '428.5px', maxWidth: '342.5px', minHeight: '428.5px', minWidth: '342.5px', */}
                </Container>
            </div>
        );
    }
}

export default Home;