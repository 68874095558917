import React, { Component } from "react";

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
//import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';



//photos
import onlinestudio from '../../images/onlinestudio.jpg';

class Instructors extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        //this.props.history.push("/instructors");
    }

    render() {

        return (
            <div className="h-100">
                <Navbar expand="lg" variant={this.state.variant} bg={this.state.variant}>
                    <Container>
                        <Navbar.Brand style={{ margin: '20px', fontSize: '150%', textWrap: 'wrap' }}>Join Online Yoga Classes </Navbar.Brand>
                    </Container>
                </Navbar>

                <Container >
                    <p>No time for in-person sessions? Enjoy the convenience of online yoga classes via Zoom, led by Art, once a week.
                        Classes typically take place on Saturdays or Sundays at 10 AM EST. Can't attend live? No problem—you'll automatically receive a recording of the class by email.</p>
                    <p>These classes are open to all levels of yoga experience.</p>
                    <p>
                        <strong>Cost: </strong> $30 per month
                    </p>
                    <p>
                        <strong>To Sign Up: </strong> Email Art at <a href="mailto:artofyogacanada@gmail.com">artofyogacanada@gmail.com</a>
                    </p>
                    <p>
                        Find your flow—anytime, anywhere!
                    </p>
<Col>
<Image fluid src={onlinestudio} alt="online studio" style={{ margin: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'cover', border: '10px' }} />
</Col>
                   
                </Container>
            </div>
        );
    }
}

export default Instructors;