import React, { Component } from "react";
import restService from '../../utils/restService';

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

class Classes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            classes: [
               
                // {
                //     weekDayCode: 2,
                //     dayOfTheWeek: 'Tuesday',
                //     daySchedule: [
                //         {
                //             time: '5-5:45 PM',
                //             classType: 'Flow',
                //             instructor: 'Art',
                //             location: 'Goods & Co.'
                //         }
                //     ]

                // },
                
                {
                    weekDayCode: 5,
                    dayOfTheWeek: 'Friday',
                    daySchedule: [
                        {
                            time: '10 AM',
                            classType: 'Hips',
                          
                        }
                    ]

                },
                {
                    weekDayCode: 6,
                    dayOfTheWeek: 'Saturday',
                    daySchedule: [
                        {
                            time: '10 AM',
                            classType: 'Online',
                        
                        },
                        {
                            time: '11 AM',
                            classType: 'Power',
                  
                        },
                        {
                            time: '12 PM',
                            classType: 'Cold Plunge',
                      
                        }
                    ]

                },
                {
                    weekDayCode: 7,
                    dayOfTheWeek: 'Sunday',
                    daySchedule: [
                        {
                            time: '11 AM',
                            classType: 'Sunday Funday Flow',
                        
                        },
                        {
                            time: '12 PM',
                            classType: 'Cold Plunge',
                        
                        }
                    ]

                }
            ]
        };
    }

    componentDidMount() {
        //this.props.history.push("/classes");
        //this.getProducts();
    }

    //#region API
    getClasses = () => {
        restService.classes.getClasses().then(res => {
            const data = res.data;

            //console.log("logging data: ", data);
            this.setState({ classes: data });
        }).catch(err => {
            console.log("Unexpected error while retrieving products.");
        });
    }

    //#endregion

    //#region EVENTS
    //#endregion

    //#region HANDLERS
    //#endregion

    //#region DISPLAY
    //#endregion

    render() {
        const { classes } = this.state;

        return (
            <div className="h-100">
                <Navbar expand="lg" variant={this.state.variant} bg={this.state.variant}>
                    <Container>
                        <Navbar.Brand style={{ margin: '20px', fontSize: '150%' }}>Classes</Navbar.Brand>
                    </Container>
                </Navbar>

                <Container style={{ background: '#f8f9fa' }}>
                    <Row xs={1} md={4} className="g-4">
                        {classes.map((yogaClass, index) => (
                            <Col key={index}>
                                <Card style={{ margin: '10px', border: '0px', minHeight: '250px' }}>
                                    <Card.Header style={{ background: '#99ddff' }}>{yogaClass.dayOfTheWeek}</Card.Header>
                                    <Card.Body>
                                        {yogaClass.daySchedule.map((sched, index) => (

                                            <Row key={index} style={{ margin: '10px' }}>
                                                <div>{sched.time}</div>
                                                <div>{sched.classType}</div>
                                                <div>{sched.location}</div>
                                            </Row>

                                        ))}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>

                <Container>

                    <Button onClick={() => { window.location.href = 'https://bookwhen.com/artofyoga' }} style={{ margin: '20px', background: '#99ddff', border: '0px', color: 'black', borderRadius: '0px' }}>
                        Book Class Here
                    </Button>
                </Container>

            </div>
        );
    }
}

export default Classes;